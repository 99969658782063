<template>
  <div v-if="!auth.account"
       class="d-flex flex-column align-items-center p-4"
       style="background-color: #9f8855; max-height: 20%; height: 100%"
  >
    <h3 style="color: #ffffff">EMVIA LIVING Gruppe</h3>
    <p class="mb-0" style="color: #ffffff">Süderstraße 77</p>
    <p class="mb-2" style="color: #ffffff">20097 Hamburg</p>
    <p class="mb-0" style="color: #ffffff">
      <a href="mailto:info@emvia.de" style="color: white; text-decoration: none">
        <FontAwesomeIcon
            :icon="['fas', 'envelope']"
            style="width: 12px; margin-bottom: 0!important; padding-bottom: 0!important; vertical-align: bottom; color: white"
            class="fs-4 m-2"
        />
        info@emvia.de</a>
    </p>
  </div>
</template>

<script setup lang="ts">
import {useAuthStore} from "@/store/auth";

const auth = useAuthStore();
</script>